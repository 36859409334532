import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  get_user_data(payload) {
    return api
      .post("get_user_data/", payload)
      .then((response) => response.data);
  },
  save_cv(payload) {
    return multiform
      .post("save_cv/", payload)
      .then((response) => response.data);
  },
  alertMessageSeen() {
    return api.post("message_seen/").then((response) => response.data);
  },
  delete_cv(payload) {
    return api.post("delete_cv/", payload).then((response) => response.data);
  },
  send_feedback(payload) {
    return api
      .post("send_feedback/", payload)
      .then((response) => response.data);
  },
  checkUserCompleted(school) {
    return api
      .get(`users/check_info/?school=${school}`)
      .then((response) => response.data);
  },
  get_my_points(payload) {
    return api
      .post("get_my_points/", payload)
      .then((response) => response.data);
  },
  set_country(payload) {
    return api.post("set_country/", payload).then((response) => response.data);
  },
  updateUser(payload, userID) {
    return api
      .patch(`users/${userID}/`, payload)
      .then((response) => response.data);
  },
  getUserDataByID(payload) {
    return api
      .post("get_user_data_by_id/", payload)
      .then((response) => response.data);
  },
  deleteAccount() {
    return api.post("delete_account/").then((response) => response.data);
  },
  addTransactionPoints(payload) {
    return api
      .post("add_transaction_points/", payload)
      .then((response) => response.data);
  },
};
