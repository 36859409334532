<template>
  <div>
    <b-dropdown variant="link" no-caret>
      <template v-slot:button-content>
        <div
          class="value-container"
          v-bind:class="getValueClasses()"
          :title="getTitle()"
          @click="$emit('setDoc', setDocumentFunction)"
          href="#"
          tabindex="0"
          :id="'tooltip-target-'+ docType.document_info.student_grade_document_type_id"
          
        >
          <div>
            <v-icon class="icon" scale="0.9" :name="getValueIcon()" />
            <v-icon class="icon" scale="0.65" name="chevron-down" />
          </div>
        </div>
      </template>
      <b-dropdown-item
        href="#"
        @click="handleWorkOnline()"
      >
        <v-icon class="icon" scale="0.9" name="mouse-pointer" />
        <span v-if="$store.state.login.schoolLevel > 0">TRABAJAR EN LINEA</span>
        <span v-else>VER</span>
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        v-if="docType.document_info.document !== null && docType.name !== 'FUDEI'"
        @click="download_document()"
      >
        <v-icon class="icon" scale="0.9" name="download" />DESCARGAR
      </b-dropdown-item>
      <b-dropdown-item v-if="canUploadFile()" 
        href="#" @click="show_modal_upload_document()" :disabled="$store.state.login.schoolLevel < 1">
        <v-icon class="icon" scale="0.9" name="upload" />SUBIR ARCHIVO
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="archived_document()" :disabled="$store.state.login.schoolLevel < 1">
        <v-icon class="icon" scale="0.9" name="folder-open" />MARCAR COMO IMPRESO Y LEIDO
      </b-dropdown-item>
      <b-dropdown-item href="#" v-if="docType.document_info.document !== null" @click="deleteDocument()" :disabled="$store.state.login.schoolLevel < 1">
        <v-icon class="icon" scale="0.9" name="times" />ELIMINAR
      </b-dropdown-item>
      <b-dropdown-item href="#" v-if="docType.document_info.document === null" @click="restoreDocument()" :disabled="$store.state.login.schoolLevel < 1">
        <v-icon class="icon" scale="0.9" name="trash-restore" />RECUPERAR ARCHIVO ELIMINADO
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        v-if="docType.document_info.document === null"
        @click="unassignDocumentType()"
        :disabled="$store.state.login.schoolLevel < 1"
      >
        <v-icon class="icon" scale="0.9" name="folder-open" />NO APLICA
      </b-dropdown-item>
    </b-dropdown>
    <b-tooltip
      v-if="docType.document_info.required_tests.length>0 && tests"
      :target="'tooltip-target-'+ docType.document_info.student_grade_document_type_id"
      triggers="hover"
      boundary="window"
    >
      <div style="text-align: left">
        Test necesarios:
        <ul style="padding-left: 1rem; list-style: none;">
          <li v-for="(required_test, index_test) in docType.document_info.required_tests" v-bind:key="index_test">
            <v-icon :name="getIconTest(required_test.test)" :color="getColorIconTest(required_test.test)"/> {{required_test.name}}
          </li>
        </ul>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import redpieService from "../../../services/redpieService";

export default {
  props: {
    docType: {
      type: Object,
      required: true
    },
    tests: {
      type: Array,
      required: false
    },
    grade_index: {
      type: Number,
      required: false
    },
    student_index: {
      type: Number,
      required: false
    },
    document_index: {
      type: Number,
      required: false
    },
    diagnostic: {
      type: Number,
      required: false
    },
  },
  created() {
    // move to index, could be in a mixin
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data);
      if (message.type === "document_updated") {
        if (this.docType.document_info.student_grade_document_type_id === message.student_grade_document_type) {
          var previous_doc = this.docType.document_info.document;
          var docID = message.document !== null? message.document.id : null;
          this.docType.document_info.document = message.document;
          this.$emit('document-updated', message.student_grade_document_type, docID, previous_doc)
        }
      }
    }
    if (this.grade_index === 0 && this.student_index === 0 &&
        this.document_index === 0 && this.docType.document_info.document !== null){
      this.$emit('first-element-data', {
        student_grade_document_type_id: this.docType.document_info
          .student_grade_document_type_id,
        document_id: this.docType.document_info.document? this.docType.document_info.document.id: ''})
    }
    if (this.grade_index === 0 && this.student_index === 0 &&
        this.docType.name === 'Informe Psicopedagógico' && this.docType.document_info.document !== null){
      this.$emit('test-element-data', {
        student_grade_document_type_id: this.docType.document_info
          .student_grade_document_type_id,
        document_id: this.docType.document_info.document.id,
        student_index: this.student_index,
        document_index: this.document_index})
    }
  },
  methods: {
    setDocumentFunction: function(doc) {
      this.docType.document_info.document = doc;
    },
    unassignDocumentType: function() {
      redpieService
        .unassignDocumentType({
          document_type: this.docType.document_info
            .student_grade_document_type_id
        })
        .then(data => {
          this.docType.document_info.has_document_type = false;
          this.$toasted.success(data.message);
        });
    },
    getValueClasses() {
      if (this.docType.document_info.document === null) {
        return [
          "text-redpie",
          "text-ultralight-hover",
          "bg-redpie-hover",
          "border-redpie"
        ];
      } else if (this.docType.document_info.document.state === 1) {
        return [
          "text-primary",
          "text-ultralight-hover",
          "bg-primary-hover",
          "border-primary"
        ];
      } else if (this.docType.document_info.document.state === 2) {
        return [
          "text-secondary",
          "text-ultralight-hover",
          "bg-secondary-hover",
          "border-secondary"
        ];
      } else if (this.docType.document_info.document.state === 3) {
        return [
          "text-tertiary",
          "text-ultralight-hover",
          "bg-tertiary-hover",
          "border-tertiary"
        ];
      }
    },
    getValueIcon() {
      if (this.docType.document_info.document === null) {
        return "times";
      } else if (this.docType.document_info.document.state === 1) {
        return "minus";
      } else if (this.docType.document_info.document.state === 2) {
        return "check";
      } else if (this.docType.document_info.document.state === 3) {
        return "folder-open";
      }
    },
    view_document: function() {
      this.$emit("view-document", {
        student_grade_document_type_id: this.docType.document_info
          .student_grade_document_type_id,
        document_id: this.docType.document_info.document.id,
        student_index: this.student_index,
        document_index: this.document_index,
        is_online: this.docType.is_online
      });
    },
    show_modal_upload_document: function() {
      if (this.$store.state.login.token) {
        this.$emit("show-modal-upload-document", {
          student_grade_document_type_id: this.docType.document_info
            .student_grade_document_type_id,
          student_index: this.student_index,
          document_index: this.document_index
        });
      }
      else {
        this.$toasted.error("Regístrate para utilizar esta funcionalidad!");
      }
    },
    download_document: function() {
      this.$emit("download-document", {
        document_id: this.docType.document_info.document.id,
        is_online: this.docType.document_info.document.is_online,
        student_index: this.student_index,
        document_index: this.document_index,
        is_multi: this.docType.is_multi
      });
    },
    deleteDocument: function() {
      this.$emit("delete-document", {
        document_id: this.docType.document_info.document.id,
        student_index: this.student_index,
        document_index: this.document_index
      });
    },
    restoreDocument: function() {
      this.$emit("restore-document", {
        student_grade_document_type_id: this.docType.document_info
          .student_grade_document_type_id,
        student_index: this.student_index,
        document_index: this.document_index
      });
    },
    archived_document: function() {
      const document_id =
        this.docType.document_info.document != null
          ? this.docType.document_info.document.id
          : "";
      this.$emit("archived-document", {
        student_grade_document_type_id: this.docType.document_info
          .student_grade_document_type_id,
        document_id: document_id,
        student_index: this.student_index,
        document_index: this.document_index
      });
    },
    showTemplates: function() {
      if (this.$store.state.login.points > -1 || !this.$store.state.login.token){
        if (this.$store.state.login.schoolLevel < 1){
          this.$toasted.error('No puedes ver un documento no iniciado!');
          return;
        }
        this.getDocumentTypeTemplates();
      }
      else {
        this.$store.commit('login/changeBuy')
      }
    },
    getDocumentTypeTemplates: function() {
      redpieService
        .get_document_templates({
          document_type_id: this.docType.id
        })
        .then(data => {
          this.$emit(
            "open-templates",
            data.document_templates,
            this.docType.document_info.student_grade_document_type_id,
            this.student_index,
            this.document_index,
            this.diagnostic,
            this.docType.is_online
          );
        });
    },
    getIconTest: function(test) {
      return this.tests.some(item => {
        return item.test === test
      })? "check" : "times"
    },
    getColorIconTest: function(test) {
      return this.tests.some(item => {
        return item.test === test
      })? "green" : "red"
    },
    getTitle() {
      if (this.docType.document_info.document === null) {
        return "Sin empezar";
      } else if (this.docType.document_info.document.state === 1) {
        return "Sin terminar";
      } else if (this.docType.document_info.document.state === 2) {
        return "Completado";
      } else if (this.docType.document_info.document.state === 3) {
        return "Archivado";
      }
    },
    isFudei: function() {
      return this.docType.name === 'FUDEI'
    },
    openFudei: function() {
      if (this.docType.document_info.document) {
        this.$emit("show-fudei", 
                    this.docType.document_info.document.id, 
                    this.docType.document_info.student_grade_document_type_id, 
                    this.docType.document_info.document.state);
      } else {
        this.$emit("create-fudei", this.docType.document_info.student_grade_document_type_id);
      }
    },
    view_multiple: function() {
      this.$emit('view-multiple', this.docType.document_info.student_grade_document_type_id)
    },
    handleWorkOnline: function() {
      if (this.isFudei()) {
        this.openFudei();
      }
      else if (this.docType.is_multi) {
        this.view_multiple();
      }
      else if (this.docType.document_info.document !== null){
        this.view_document();
      }
      else {
        this.showTemplates();
      }
    },
    canUploadFile() {
      if (this.docType.name === 'FU DEI PDF' || this.docType.name === 'FU de reevaluación online' || this.docType.name.substring(0, 2) !== 'FU') {
        return this.docType.document_info.document === null || !this.docType.document_info.document.is_online;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.value-container {
  cursor: pointer;
  margin: 0 auto;
  width: 56px;
  height: 24px;
  background-color: white;
  border: solid 1px;
  border-radius: 16px;
  font-size: 0.5em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    margin-left: 2px;
    margin-right: 2px;
  }
}
.dropdown-item {
  color: grey !important;

  .a {
    color: black !important;
  }
}
</style>
