<template lang="">
  <div>
    <b-row>
      <b-col cols="6">
        <b-select
          v-model="answer.kind"
          @change="handleChange"
          :disabled="Object.keys(answer.answers).length > 0"
        >
          <b-form-select-option :value="null"
            >Selecciona un Evalua</b-form-select-option
          >
          <b-form-select-option
            v-for="(test, index) in tests"
            :key="index"
            :value="index"
            >Evalua {{ index }}</b-form-select-option
          >
        </b-select>
      </b-col>
      <b-col>
        <b-button pill variant="primary" @click="clearAnswer(answer.kind)">
          Limpiar y cambiar el nivel de evalua
        </b-button>
      </b-col>
    </b-row>

    <GenericTest
      v-if="answer.kind != null"
      :test="tests[answer.kind]"
      :test_id="test_id"
      :answer="answer"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    oldEvaluation() {
      if (this.oldEvaluation >= 0) {
        this.answer.kind = this.oldEvaluation;
      }
    },
  },
  computed: {
    oldEvaluation() {
      if (Object.keys(this.previous_answer).length) {
        let firstVal = parseInt(Object.keys(this.previous_answer)[0]);
        if (firstVal >= 0 && firstVal < 38) return 0;
        else if (firstVal >= 38 && firstVal < 89) {
          return 1;
        } else if (firstVal >= 89 && firstVal < 137) {
          return 2;
        } else if (firstVal >= 137 && firstVal < 187) {
          return 3;
        } else if (firstVal >= 187 && firstVal < 231) {
          return 4;
        } else if (firstVal >= 231 && firstVal < 286) {
          return 5;
        } else if (firstVal >= 286 && firstVal < 335) {
          return 6;
        } else if (firstVal >= 335 && firstVal < 386) {
          return 7;
        } else if (firstVal >= 386 && firstVal < 441) {
          return 8;
        } else if (firstVal >= 441 && firstVal < 489) {
          return 9;
        } else if (firstVal >= 489) {
          return 10;
        }
      }
      return -1;
    },
  },
  created() {
    if (this.oldEvaluation >= 0) {
      this.answer.kind = this.oldEvaluation;
    }
  },
  methods: {
    handleChange(value) {
      this.clearAnswer(value);
      // console.log(value);
    },
    clearAnswer(value) {
      this.answer = {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: value,
      };
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: null,
      },
      tests: [
        [
          { type: "h3", label: "Clasificacion" },
          {
            label: "Aciertos Tarea 1",
            var: "0",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "1",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "2",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "3",
            type: "number",
          },
          { type: "h3", label: "Series" },
          {
            label: "Aciertos Tarea 1",
            var: "4",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "5",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "6",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "7",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "8",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "9",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Caballo (ingresar errores + omisiones)",
            var: "10",
            type: "number",
          },
          {
            label: "Dinosaurio (errores + omisiones)",
            var: "11",
            type: "number",
          },
          {
            label: "Uvas (errores + omisiones)",
            var: "12",
            type: "number",
          },
          {
            label: "Platanos (errores + omisiones)",
            var: "13",
            type: "number",
          },
          { type: "h3", label: "Letras y numeros" },
          {
            label: "Aciertos Tarea 1",
            var: "14",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "15",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "16",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "17",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "18",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "19",
            type: "number",
          },
          { type: "h3", label: "Memoria verbal" },
          {
            label: "Aciertos Tarea 1",
            var: "20",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "21",
            type: "number",
          },
          { type: "h3", label: "Copia de dibujo" },
          {
            label: "Aciertos Tarea 1",
            var: "22",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "23",
            type: "number",
          },
          { type: "h3", label: "Grafomotricidad" },
          {
            label: "Errores Tarea 1",
            var: "24",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "25",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "26",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "27",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "28",
            type: "number",
          },
          {
            label: "Errores Tarea 6",
            var: "29",
            type: "number",
          },
          { type: "h3", label: "Palabras y frases" },
          {
            label: "Puntaje",
            var: "30",
            type: "number",
          },
          { type: "h3", label: "Recepcion auditiva y articulacion" },
          {
            label: "Aciertos Tarea 1",
            var: "31",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "32",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "33",
            type: "number",
          },
          { type: "h3", label: "Habilidades fonologicas" },
          {
            label: "Aciertos Tarea 1",
            var: "34",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "35",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "36",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "37",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Memoria y atencion" },
          {
            label: "Aciertos Tarea 1",
            var: "38",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "39",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "40",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "41",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "42",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "43",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "44",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "45",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "46",
            type: "number",
          },
          { type: "h3", label: "Series" },
          {
            label: "Aciertos Tarea 1",
            var: "47",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "48",
            type: "number",
          },
          {
            label: "",
            var: "49",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "50",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "51",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "52",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "53",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "54",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "55",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "56",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "57",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "58",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Clasificaciones" },
          {
            label: "Aciertos Tarea 1",
            var: "59",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "60",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "61",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "62",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "63",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "64",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "65",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "66",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "67",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "68",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "69",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "70",
            type: "number",
          },
          { type: "h3", label: "Exactitud lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "71",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "72",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "73",
            type: "number",
          },
          {
            label: "PuntajeT4",
            var: "74",
            type: "number",
          },
          {
            label: "PuntajeT5",
            var: "75",
            type: "number",
          },
          {
            label: "PuntajeT6",
            var: "76",
            type: "number",
          },
          { type: "h3", label: "Ortografia fonetica" },
          {
            label: "Aciertos Tarea 1",
            var: "77",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "78",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "79",
            type: "number",
          },
          { type: "h3", label: "Grafia y expresion escrita" },
          {
            label: "Puntaje",
            var: "80",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual" },
          {
            label: "Aciertos Tarea 1",
            var: "81",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1",
            var: "83",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "84",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "85",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "86",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "87",
            type: "number",
          },
          {
            label: "Aciertos Tarea 6",
            var: "88",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Pensamiento analogico" },
          {
            label: "Aciertos Tarea 1",
            var: "89",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "90",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "91",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "92",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "93",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "94",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "95",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "96",
            type: "number",
          },
          { type: "h3", label: "Clasificaciones" },
          {
            label: "Aciertos Tarea 1",
            var: "97",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "98",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "99",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "100",
            type: "number",
          },
          { type: "h3", label: "Memoria y atencion" },
          {
            label: "Aciertos Tarea 1",
            var: "101",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "102",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "103",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "104",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "105",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "106",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "107",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "108",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "109",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "110",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "111",
            type: "number",
          },
          {
            label: "Omisiones Tarea 4",
            var: "112",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "113",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "114",
            type: "number",
          },
          {
            label: "Omisiones Tarea 5",
            var: "115",
            type: "number",
          },
          {
            label: "Aciertos Tarea 6",
            var: "116",
            type: "number",
          },
          {
            label: "Errores Tarea 6",
            var: "117",
            type: "number",
          },
          {
            label: "Omisiones Tarea 6",
            var: "118",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "119",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "120",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "121",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "122",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "123",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "124",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "125",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "126",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "127",
            type: "number",
          },
          { type: "h3", label: "Exactitud lectora" },
          {
            label: "Puntaje",
            var: "128",
            type: "number",
          },
          { type: "h3", label: "Grafia" },
          {
            label: "Puntaje",
            var: "129",
            type: "number",
          },
          { type: "h3", label: "Ortografia" },
          {
            label: "Aciertos Tarea 1",
            var: "130",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "131",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Parte 1 (1 a 49)",
            var: "132",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (50 a 55)",
            var: "133",
            type: "number",
          },
          {
            label: "Errores Parte 2 (50 a 55)",
            var: "134",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Tarea 1",
            var: "135",
            type: "number",
          },
          {
            label: "",
            var: "136",
            value: 0,
            type: "hidden",
          },
        ],
        [
          { type: "h3", label: "Memoria y atencion" },
          {
            label: "Aciertos Tarea 1",
            var: "137",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "138",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "139",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "140",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "141",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "142",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "143",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "144",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "145",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "146",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "147",
            type: "number",
          },
          {
            label: "Omisiones Tarea 4",
            var: "148",
            type: "number",
          },
          { type: "h3", label: "Reflexividad" },
          {
            label: "Aciertos Tarea 1",
            var: "149",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "150",
            type: "number",
          },
          { type: "h3", label: "Pensamiento analogico" },
          {
            label: "Aciertos Tarea 1",
            var: "151",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "152",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "153",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "154",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "155",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "156",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "157",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "158",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "159",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "160",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Suma de todos los aciertos:",
            var: "161",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "162",
            type: "number",
          },
          {
            label: "",
            var: "163",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "164",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "165",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "166",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "167",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "168",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Exactitud lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "169",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "170",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "171",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "172",
            type: "number",
          },
          { type: "h3", label: "Ortografia fonetica" },
          {
            label: "Aciertos Tarea 1",
            var: "173",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "174",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "175",
            type: "number",
          },
          { type: "h3", label: "Grafia y expresion escrita" },
          {
            label: "Puntaje",
            var: "176",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Aciertos Tarea 1 (Escritura de palabras)",
            var: "177",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (Escritura de palabras)",
            var: "178",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (Reconocimiento ortografico)",
            var: "179",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (Reconocimiento ortografico)",
            var: "180",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2 (Reconocimiento ortografico)",
            var: "181",
            type: "number",
          },
          {
            label: "Errores Tarea 3 (dictado de texto)",
            var: "182",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1",
            var: "183",
            type: "number",
          },
          {
            label: "",
            var: "184",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Parte 1 (1 a la 15)",
            var: "185",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (16 en adelante)",
            var: "186",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Memoria y atencion" },
          {
            label: "Aciertos Tarea 1",
            var: "187",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "188",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "189",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "190",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "191",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "192",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "193",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "194",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "195",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "196",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "197",
            type: "number",
          },
          {
            label: "Omisiones Tarea 4",
            var: "198",
            type: "number",
          },
          { type: "h3", label: "Reflexividad" },
          {
            label: "Suma de todos los aciertos",
            var: "199",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "200",
            type: "number",
          },
          { type: "h3", label: "Pensamiento analogico" },
          {
            label: "Aciertos Tarea 1",
            var: "201",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "202",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "203",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "204",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "205",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "206",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "207",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "208",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "209",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "210",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "211",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "212",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Parte 1 (1 a la 17)",
            var: "213",
            type: "number",
          },
          {
            label: "Errores Parte 1 (1 a la 17)",
            var: "214",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (18 a 33)",
            var: "215",
            type: "number",
          },
          {
            label: "Errores Parte 2 (18 a 33)",
            var: "216",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "217",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "218",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "219",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "220",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Errores Tarea 1 (dictado)",
            var: "221",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (reconocimiento ortografico)",
            var: "222",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (reconocimiento ortografico)",
            var: "223",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2 (reconocimiento ortografico)",
            var: "224",
            type: "number",
          },
          { type: "h3", label: "Grafia y expresion escrita" },
          {
            label: "Puntaje",
            var: "225",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1",
            var: "226",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "227",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "228",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "229",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Tarea 1",
            var: "230",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Memoria y atencion" },
          {
            label: "Aciertos Tarea 1",
            var: "231",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "232",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "233",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "234",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "235",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "236",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "237",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "238",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "239",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "240",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "241",
            type: "number",
          },
          {
            label: "Omisiones Tarea 4",
            var: "242",
            type: "number",
          },
          { type: "h3", label: "Reflexividad" },
          {
            label: "Suma de todos los aciertos",
            var: "243",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "244",
            type: "number",
          },
          { type: "h3", label: "Pensamiento analogico" },
          {
            label: "Aciertos Tarea 1",
            var: "245",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "246",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "247",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "248",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "249",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "250",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "251",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "252",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Suma de todos los aciertos",
            var: "253",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "254",
            type: "number",
          },
          {
            label: "",
            var: "255",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "256",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "257",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "258",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "259",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "260",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "261",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "262",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "263",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "264",
            type: "number",
          },
          { type: "h3", label: "Exactitud lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "265",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "266",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "267",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "268",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "269",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "270",
            type: "number",
          },
          { type: "h3", label: "Ortografia fonetica" },
          {
            label: "Aciertos Tarea 1",
            var: "271",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "272",
            type: "number",
          },
          { type: "h3", label: "Grafia y expresion escrita" },
          {
            label: "Puntaje",
            var: "273",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Aciertos Tarea 1 (Reconocimiento ortografico)",
            var: "274",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (Reconocimiento ortografico)",
            var: "275",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1 (Reconocimiento ortografico)",
            var: "276",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (dictado)",
            var: "277",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1 y 2 (ejercicios 1 al 21)",
            var: "278",
            type: "number",
          },
          {
            label: "Errores Tarea 1 y 2 (ejercicios 1 al 21)",
            var: "279",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1 y 2 (ejercicios 1 al 21)",
            var: "280",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "281",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "282",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "283",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Parte 1 (1 a la 23)",
            var: "284",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (24 a la 30)",
            var: "285",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Reflexividad" },
          {
            label: "Aciertos Tarea 1",
            var: "286",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "287",
            type: "number",
          },
          { type: "h3", label: "Pensamiento analogico" },
          {
            label: "Aciertos Tarea 1",
            var: "288",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "289",
            type: "number",
          },
          { type: "h3", label: "Organizacion perceptiva" },
          {
            label: "Aciertos Tarea 1",
            var: "290",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "291",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "292",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "293",
            type: "number",
          },
          { type: "h3", label: "Memoria" },
          {
            label: "Aciertos Tarea 1",
            var: "294",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "295",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "296",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "297",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "298",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "299",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "300",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "301",
            type: "number",
          },
          {
            label: "Omisiones Tarea 3",
            var: "302",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "303",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "304",
            type: "number",
          },
          {
            label: "Omisiones Tarea 4",
            var: "305",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "306",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "307",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "308",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "309",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "310",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "311",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (preguntas 1 a la 5)",
            var: "312",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (preguntas 1 a la 5)",
            var: "313",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (preguntas 6 a la 9)",
            var: "314",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (preguntas 6 a la 9)",
            var: "315",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3 (preguntas 1 a la 9)",
            var: "316",
            type: "number",
          },
          {
            label: "Errores Tarea 3 (preguntas 1 a la 9)",
            var: "317",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3 (preguntas 10 a 14)",
            var: "318",
            type: "number",
          },
          {
            label: "Errores Tarea 3 (preguntas 10 a 14)",
            var: "319",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "320",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "321",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "322",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "323",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Aciertos Tarea 1 (dictado)",
            var: "324",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (Reconocimiento ortografico)",
            var: "325",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (Reconocimiento ortografico)",
            var: "326",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2 (Reconocimiento ortografico)",
            var: "327",
            type: "number",
          },
          { type: "h3", label: "Grafismo y ortografia fonetica" },
          {
            label: "Puntaje",
            var: "328",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1 (ejercicios 1 al 9)",
            var: "329",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (ejercicios 1 al 9)",
            var: "330",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (ejercicios 10 al 12)",
            var: "331",
            type: "number",
          },
          {
            label: "Aciertos Tareas 3, 4 y 5 (ejercicios 13 al 35)",
            var: "332",
            type: "number",
          },
          {
            label: "Errores Tareas 3, 4 y 5 (ejercicios 13 al 35)",
            var: "333",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Suma de todos los aciertos",
            var: "334",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Atencion concentracion" },
          {
            label: "Aciertos Tarea 1",
            var: "335",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "336",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "337",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "338",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "339",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "340",
            type: "number",
          },
          { type: "h3", label: "Razonamiento deductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "341",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "342",
            type: "number",
          },
          { type: "h3", label: "Razonamiento inductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "343",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "344",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "345",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "346",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "347",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "348",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "349",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "350",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "351",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "352",
            type: "number",
          },
          { type: "h3", label: "Razonamiento espacial" },
          {
            label: "Aciertos Tarea 1",
            var: "353",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "354",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "355",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "356",
            type: "number",
          },
          { type: "h3", label: "Actitud motivacion" },
          {
            label: "Puntaje",
            var: "357",
            type: "number",
          },
          { type: "h3", label: "Autocontrol y autonomia" },
          {
            label: "Puntaje",
            var: "358",
            type: "number",
          },
          { type: "h3", label: "Conductas pro sociales" },
          {
            label: "Puntaje",
            var: "359",
            type: "number",
          },
          { type: "h3", label: "Autoconcepto y autoestima" },
          {
            label: "Puntaje",
            var: "360",
            type: "number",
          },
          { type: "h3", label: "Eficacia lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "361",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "362",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "363",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "364",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "365",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "366",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "367",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "368",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "369",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "370",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "371",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "372",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "373",
            type: "number",
          },
          { type: "h3", label: "Ortografia fonetica" },
          {
            label: "Aciertos Tarea 1",
            var: "374",
            type: "number",
          },
          { type: "h3", label: "Expresion escrita" },
          {
            label: "Puntaje",
            var: "375",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Aciertos Tarea 1 (Reconocimiento ortografico)",
            var: "376",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (Reconocimiento ortografico)",
            var: "377",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1 (Reconocimiento ortografico)",
            var: "378",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (Eleccion de la ortografia correcta)",
            var: "379",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (Eleccion de la ortografia correcta)",
            var: "380",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2 (Eleccion de la ortografia correcta)",
            var: "381",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1",
            var: "382",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Parte 1 (1 a la 26)",
            var: "383",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (27 a la 34)",
            var: "384",
            type: "number",
          },
          { type: "h3", label: "Estrategias de trabajo y estudio" },
          {
            label: "Puntaje",
            var: "385",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Atencion concentracion" },
          {
            label: "Aciertos Tarea 1",
            var: "386",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "387",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "388",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "389",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "390",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "391",
            type: "number",
          },
          { type: "h3", label: "Razonamiento inductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "392",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "393",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "394",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "395",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "396",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "397",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "398",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "399",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "400",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "401",
            type: "number",
          },
          {
            label: "Aciertos Tarea 6",
            var: "402",
            type: "number",
          },
          {
            label: "Errores Tarea 6",
            var: "403",
            type: "number",
          },
          { type: "h3", label: "Razonamiento espacial" },
          {
            label: "Aciertos Tarea 1",
            var: "404",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "405",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "406",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "407",
            type: "number",
          },
          { type: "h3", label: "Razonamiento deductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "408",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "409",
            type: "number",
          },
          { type: "h3", label: "Adaptacion personal" },
          {
            label: "Puntaje",
            var: "410",
            type: "number",
          },
          { type: "h3", label: "Adaptacion familiar" },
          {
            label: "Puntaje",
            var: "411",
            type: "number",
          },
          { type: "h3", label: "Adaptacion escolar" },
          {
            label: "Puntaje",
            var: "412",
            type: "number",
          },
          { type: "h3", label: "Habilidades sociales" },
          {
            label: "Puntaje",
            var: "413",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "414",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "415",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "416",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "417",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "418",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "419",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "420",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "421",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "422",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "423",
            type: "number",
          },
          { type: "h3", label: "Eficacia lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "424",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "425",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "426",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "427",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "428",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "429",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Aciertos Tarea 1 (Reconocimiento ortografico)",
            var: "430",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (Reconocimiento ortografico)",
            var: "431",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1 (Reconocimiento ortografico)",
            var: "432",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (Eleccion de la ortografia correcta)",
            var: "433",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (Eleccion de la ortografia correcta)",
            var: "434",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2 (Eleccion de la ortografia correcta)",
            var: "435",
            type: "number",
          },
          {
            label: "Errores de dictado",
            var: "436",
            type: "number",
          },
          { type: "h3", label: "Expresion escrita" },
          {
            label: "Puntaje",
            var: "437",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Aciertos Tarea 1",
            var: "438",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Tarea 1",
            var: "439",
            type: "number",
          },
          { type: "h3", label: "Estrategias de trabajo y estudio" },
          {
            label: "Puntaje",
            var: "440",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Atencion concentracion" },
          {
            label: "Aciertos Tarea 1",
            var: "441",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "442",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "443",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "444",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "445",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "446",
            type: "number",
          },
          { type: "h3", label: "Razonamiento inductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "447",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "448",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "449",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "450",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "451",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "452",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "453",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "454",
            type: "number",
          },
          {
            label: "Aciertos Tarea 5",
            var: "455",
            type: "number",
          },
          {
            label: "Errores Tarea 5",
            var: "456",
            type: "number",
          },
          {
            label: "Aciertos Tarea 6",
            var: "457",
            type: "number",
          },
          {
            label: "Errores Tarea 6",
            var: "458",
            type: "number",
          },
          { type: "h3", label: "Razonamiento espacial" },
          {
            label: "Aciertos Tarea 1 (preguntas 1 a la 7)",
            var: "459",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (preguntas 1 a la 7)",
            var: "460",
            type: "number",
          },
          {
            label: "Aciertos Tarea 1 (preguntas 8 y 9)",
            var: "461",
            type: "number",
          },
          {
            label: "Errores Tarea 1 (preguntas 8 y 9)",
            var: "462",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2 (10 al 20)",
            var: "463",
            type: "number",
          },
          {
            label: "Errores Tarea 2 (10 al 20)",
            var: "464",
            type: "number",
          },
          { type: "h3", label: "Razonamiento deductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "465",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "466",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "467",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "468",
            type: "number",
          },
          { type: "h3", label: "Adaptacion personal" },
          {
            label: "Puntaje",
            var: "469",
            type: "number",
          },
          { type: "h3", label: "Adaptacion familiar" },
          {
            label: "Puntaje",
            var: "470",
            type: "number",
          },
          { type: "h3", label: "Adaptacion escolar" },
          {
            label: "Puntaje",
            var: "471",
            type: "number",
          },
          { type: "h3", label: "Habilidades sociales" },
          {
            label: "Puntaje",
            var: "472",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Suma de todos los aciertos",
            var: "473",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "474",
            type: "number",
          },
          { type: "h3", label: "Eficacia lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "475",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "476",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "477",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "478",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "479",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Suma de todos los aciertos",
            var: "480",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "481",
            type: "number",
          },
          {
            label: "Suma de todas las omisiones",
            var: "482",
            type: "number",
          },
          {
            label: "",
            var: "483",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "484",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "485",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Expresion escrita" },
          {
            label: "Puntaje",
            var: "486",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Suma de todos los aciertos",
            var: "487",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Puntaje (aproximar a un entero)",
            var: "488",
            type: "number",
          },
        ],
        [
          { type: "h3", label: "Atencion concentracion" },
          {
            label: "Aciertos Tarea 1",
            var: "489",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "490",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "491",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "492",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "493",
            type: "number",
          },
          {
            label: "Omisiones Tarea 2",
            var: "494",
            type: "number",
          },
          { type: "h3", label: "Razonamiento inductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "495",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "496",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "497",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "498",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "499",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "500",
            type: "number",
          },
          { type: "h3", label: "Razonamiento espacial" },
          {
            label: "Aciertos Parte 1 (preguntas 1 a la 3)",
            var: "501",
            type: "number",
          },
          {
            label: "Errores Parte 1 (preguntas 1 a la 3)",
            var: "502",
            type: "number",
          },
          {
            label: "Aciertos Parte 2 (preguntas 4 a 11)",
            var: "503",
            type: "number",
          },
          {
            label: "Errores Parte 2 (preguntas 4 a 11)",
            var: "504",
            type: "number",
          },
          { type: "h3", label: "Razonamiento deductivo" },
          {
            label: "Aciertos Tarea 1",
            var: "505",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "506",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "507",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "508",
            type: "number",
          },
          { type: "h3", label: "Adaptacion personal" },
          {
            label: "Puntaje",
            var: "509",
            type: "number",
          },
          { type: "h3", label: "Adaptacion familiar" },
          {
            label: "Puntaje",
            var: "510",
            type: "number",
          },
          { type: "h3", label: "Adaptacion escolar" },
          {
            label: "Puntaje",
            var: "511",
            type: "number",
          },
          { type: "h3", label: "Habilidades sociales" },
          {
            label: "Puntaje",
            var: "512",
            type: "number",
          },
          { type: "h3", label: "Comprension lectora" },
          {
            label: "Aciertos Tarea 1",
            var: "513",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "514",
            type: "number",
          },
          {
            label: "Aciertos Tarea 2",
            var: "515",
            type: "number",
          },
          {
            label: "Errores Tarea 2",
            var: "516",
            type: "number",
          },
          {
            label: "Aciertos Tarea 3",
            var: "517",
            type: "number",
          },
          {
            label: "Errores Tarea 3",
            var: "518",
            type: "number",
          },
          {
            label: "Aciertos Tarea 4",
            var: "519",
            type: "number",
          },
          {
            label: "Errores Tarea 4",
            var: "520",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (segundos)" },
          {
            label: "Puntaje",
            var: "521",
            type: "number",
          },
          { type: "h3", label: "Velocidad lectora (nivel de comprension)" },
          {
            label: "Aciertos Tarea 1",
            var: "522",
            type: "number",
          },
          {
            label: "Errores Tarea 1",
            var: "523",
            type: "number",
          },
          {
            label: "Omisiones Tarea 1",
            var: "524",
            type: "number",
          },
          { type: "h3", label: "Ortografia visual y reglada" },
          {
            label: "Suma de todos los aciertos",
            var: "525",
            type: "number",
          },
          {
            label: "Suma de todos los errores",
            var: "526",
            type: "number",
          },
          {
            label: "suma de todas las omisiones",
            var: "527",
            type: "number",
          },
          {
            label: "",
            var: "528",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "529",
            value: 0,
            type: "hidden",
          },
          {
            label: "",
            var: "530",
            value: 0,
            type: "hidden",
          },
          { type: "h3", label: "Expresion escrita" },
          {
            label: "Puntaje",
            var: "531",
            type: "number",
          },
          { type: "h3", label: "Calculo y numeracion" },
          {
            label: "Suma de todos los aciertos",
            var: "532",
            type: "number",
          },
          { type: "h3", label: "Resolucion de problemas" },
          {
            label: "Aciertos Tarea 1",
            var: "533",
            type: "number",
          },
        ],
      ],
    };
  },
};
</script>
<style lang=""></style>
