<template lang="">
  <div>
    <GenericTest
      :test="test"
      :test_id="test_id"
      :answer="answer"
      :col_size="6"
      :label_size="8"
      :input_size="4"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: { test: this.test_id, answers: {}, student_id: this.student_id },
      test: [
        {
          label: "Construcción con cubos (Diseño con cubos):",
          type: "number",
          var: "cubos",
          maxlength: "3",
        },

        {
          label: "Analogías (Semejanzas):",
          type: "number",
          var: "analogias",
          maxlength: "3",
        },

        {
          label: "Retención de dígitos:",
          type: "number",
          var: "retencion_de_digitos",
          maxlength: "3",
        },

        {
          label: "Matrices de razonamiento:",
          type: "number",
          var: "matrices_de_razonamiento",
          maxlength: "3",
        },

        {
          label: "Vocabulario:",
          type: "number",
          var: "vocabulario",
          maxlength: "3",
        },

        {
          label: "Arimética:",
          type: "number",
          var: "aritmetica",
          maxlength: "3",
        },

        {
          label: "Búsqueda de símbolos:",
          type: "number",
          var: "busqueda_de_simbolos",
          maxlength: "3",
        },

        {
          label: "Rompecabezas visuales:",
          type: "number",
          var: "rompecabezas_visuales",
          maxlength: "3",
        },

        {
          label: "Información:",
          type: "number",
          var: "informacion",
          maxlength: "3",
        },

        { label: "Claves:", type: "number", var: "claves", maxlength: "3" },

        {
          label: "Secuenciación Letras-Números:",
          type: "number",
          var: "secuenciacion_letras_numeros",
          maxlength: "3",
        },

        {
          label: "Balanzas (Peso figurado):",
          type: "number",
          var: "balanzas",
          maxlength: "3",
        },

        {
          label: "Comprensión:",
          type: "number",
          var: "comprension",
          maxlength: "3",
        },

        {
          label: "Cancelación:",
          type: "number",
          var: "cancelacion",
          maxlength: "3",
        },

        {
          label: "Retención de Imágenes:",
          type: "number",
          var: "retencion_imagenes",
          maxlength: "3",
        },

        {
          label: "Método para calcular la discrepancia:",
          type: "select",
          var: "metodo_discrepancia",
          options: [
            { value: "critico_0.01", text: "Valor crítico (0.01)" },

            { value: "critico_0.05", text: "Valor crítico (0.05)" },

            { value: "critico_0.1", text: "Valor crítico (0.1)" },

            { value: "critico_0.15", text: "Valor crítico (0.15)" },

            { value: "base_<=25", text: "Tasa base &lt;=25" },

            { value: "base_<=15", text: "Tasa base &lt;=15" },

            { value: "base_<=10", text: "Tasa base &lt;=10" },

            { value: "base_<=5", text: "Tasa base &lt;=5" },

            { value: "base_<=2", text: "Tasa base &lt;=2" },
          ],
        },

        {
          label: "comparaciones:",
          type: "select",
          var: "comparaciones",
          options: [
            { value: "cit", text: "comparar con CI total" },

            {
              value: "pip",
              text: "comparar promedio de índices principales (PIP)",
            },
          ],
        },

        {
          label: "Tipo de puntaje:",
          type: "select",
          var: "tipo",
          options: [
            { value: "Bruto", text: "Bruto" },

            { value: "Equivalente", text: "Equivalente" },
          ],
        },

        {
          label: "Edad del evaluado:",
          type: "select",
          var: "edad",
          options: [
            { value: "6.1", text: "6 años a 6 años 5 meses" },

            { value: "6.2", text: "6 años 5 meses a 7 años" },

            { value: "7.1", text: "7 años a 7 años 5 meses" },

            { value: "7.2", text: "7 años 5 meses a 8 años" },

            { value: "8.1", text: "8 años a 8 años 5 meses" },

            { value: "8.2", text: "8 años 5 meses a 9 años" },

            { value: "9.1", text: "9 años a 9 años 5 meses" },

            { value: "9.2", text: "9 años 5 meses a 10 años" },

            { value: "10.1", text: "10 años a 10 años 5 meses" },

            { value: "10.2", text: "10 años 5 meses a 11 años" },

            { value: "11.1", text: "11 años a 11 años 5 meses" },

            { value: "11.2", text: "11 años 5 meses a 12 años" },

            { value: "12.1", text: "12 años a 12 años 5 meses" },

            { value: "12.2", text: "12 años 5 meses a 13 años" },

            { value: "13.1", text: "13 años a 13 años 5 meses" },

            { value: "13.2", text: "13 años 5 meses a 14 años" },

            { value: "14.1", text: "14 años a 14 años 5 meses" },

            { value: "14.2", text: "14 años 5 meses a 15 años" },

            { value: "15.1", text: "15 años a 15 años 5 meses" },

            { value: "15.2", text: "15 años 5 meses a 16 años" },

            { value: "16.1", text: "16 años a 16 años 5 meses" },

            { value: "16.2", text: "16 años 5 meses a 17 años" },
          ],
        },

        {
          label: "Nivel de confianza:",
          type: "select",
          var: "confianza",
          options: [
            { value: "conf95", text: "95%" },

            { value: "conf90", text: "90%" },
          ],
        },

        {
          label: "Grupo de referencia Tasa base:",
          type: "select",
          var: "grup_refer",
          options: [
            { value: "total", text: "Muestra Total" },

            { value: "nivel", text: "Nivel de Habilidad" },
          ],
        },

        {
          label: "Base de comparación subpruebas ",
          type: "select",
          var: "compara_subp",
          options: [
            { value: "PPE-P", text: "PPE-P" },

            { value: "PPE-C", text: "PPE-C" },
          ],
        },

        {
          label: "Valor crítico de diferencias entre pares de índices ",
          type: "select",
          var: "signif_pares",
          options: [
            { value: "0.01", text: "0.01" },

            { value: "0.05", text: "0.05" },

            { value: "0.1", text: "0.1" },

            { value: "0.15", text: "0.15" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang=""></style>
