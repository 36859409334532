import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import alert from './modules/alert';
import sockets from './modules/sockets';
import axios from "axios"
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// models
import {Product, Review} from '@/models/marketplace/product'
import {Activity} from '@/models/redpie/activity'
import {Video} from "@/models/redpie/videos";
import {Videocall} from "@/models/redpie/videocalls";


Vue.use(Vuex);
VuexORM.use(VuexORMAxios, { axios })
const database = new VuexORM.Database()
database.register(Product)
database.register(Review)
database.register(Activity)
database.register(Video)
database.register(Videocall)


export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    login: login,
    alert: alert,
    sockets: sockets,
  },
})