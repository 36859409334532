<template>
  <div>
    <GenericTest
      :test="test"
      :required_fields="required"
      :test_id="test_id"
      :answer="answer"
      :no_margin="true"
      :previous_answer="previous_answer"
      :semester="semester"
      @submit="handleSubmit"
    ></GenericTest>
  </div>
</template>

<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: { test: this.test_id, answers: {}, student_id: this.student_id },
      required: ["edad"],
      test: [
        {
          type: "select",
          var: "edad",
          options: [
            { value: "", text: "---------" },
            { value: "3", text: "3.0 - 3.11 años" },
            { value: "4", text: "4.0 - 4.11 años" },
            { value: "5", text: "5.0 - 5.11 años" },
            { value: "6", text: "6.0 - 6.11 años" },
          ],
          label: "Edad:",
          required: true,
        },
        { label: "El niño está sentado.*:", type: "checkbox", var: "r_1_1" },
        { label: "El niño no está sentado.", type: "checkbox", var: "r_1_2" },
        {
          label: "El gato está encima de la caja.",
          type: "checkbox",
          var: "r_2_1",
        },
        {
          label: "El gato está adentro de la caja.*:",
          type: "checkbox",
          var: "r_2_2",
        },
        { label: "El está subiendo.", type: "checkbox", var: "r_3_1" },
        { label: "Ella está subiendo.*:", type: "checkbox", var: "r_3_2" },
        {
          label: "El perro está detrás de la silla.*:",
          type: "checkbox",
          var: "r_4_1",
        },
        {
          label: "El perro está debajo de la silla.",
          type: "checkbox",
          var: "r_4_2",
        },
        { label: "Están comiendo.", type: "checkbox", var: "r_5_1" },
        { label: "Está comiendo.*:", type: "checkbox", var: "r_5_2" },
        { label: "El libro es de él.*:", type: "checkbox", var: "r_6_1" },
        { label: "El libro es de ella.", type: "checkbox", var: "r_6_2" },
        { label: "El niño se cayó.*:", type: "checkbox", var: "r_7_1" },
        { label: "El niño se cae.", type: "checkbox", var: "r_7_2" },
        { label: "Alguien está en la mesa.", type: "checkbox", var: "r_8_1" },
        { label: "Algo está en la mesa.*:", type: "checkbox", var: "r_8_2" },
        { label: "El niño la está llamando.", type: "checkbox", var: "r_9_1" },
        {
          label: "El niño lo está llamando.*:",
          type: "checkbox",
          var: "r_9_2",
        },
        { label: "Este es mi papá.*:", type: "checkbox", var: "r_10_1" },
        { label: "Aquel es mi papá.", type: "checkbox", var: "r_10_2" },
        {
          label: "El niño está tomando helado.*:",
          type: "checkbox",
          var: "r_11_1",
        },
        {
          label: "El niño estaba tomando helado.",
          type: "checkbox",
          var: "r_11_2",
        },
        { label: "¿Dónde está la niña?", type: "checkbox", var: "r_12_1" },
        { label: "¿Quién es la niña?.*:", type: "checkbox", var: "r_12_2" },
        {
          label: "El niño tiene el pájaro.*:",
          type: "checkbox",
          var: "r_13_1",
        },
        { label: "El niño tenía el pájaro.", type: "checkbox", var: "r_13_2" },
        { label: "La niña las tiene.*:", type: "checkbox", var: "r_14_1" },
        { label: "La niña la tiene.", type: "checkbox", var: "r_14_2" },
        { label: "Esta es mi cama.", type: "checkbox", var: "r_15_1" },
        { label: "Esta es nuestra cama.*:", type: "checkbox", var: "r_15_2" },
        { label: "El niño se ve.", type: "checkbox", var: "r_16_1" },
        { label: "El niño lo ve.*:", type: "checkbox", var: "r_16_2" },
        { label: "La niña subirá.*:", type: "checkbox", var: "r_17_1" },
        { label: "La niña subió.", type: "checkbox", var: "r_17_2" },
        { label: "Mira a quien llegó.", type: "checkbox", var: "r_18_1" },
        { label: "Mira lo que llegó.*:", type: "checkbox", var: "r_18_2" },
        {
          label: "La mamá dice, 'Se lo dio'.",
          type: "checkbox",
          var: "r_19_1",
        },
        {
          label: "La mamá dice, 'Me lo dio'.*:",
          type: "checkbox",
          var: "r_19_2",
        },
        {
          label: "La mamá va a comprar pan.*:",
          type: "checkbox",
          var: "r_20_1",
        },
        {
          label: "La mamá fue a comprar pan.",
          type: "checkbox",
          var: "r_20_2",
        },
        { label: "Este es un avión.*:", type: "checkbox", var: "r_21_1" },
        { label: "Ese es un avión.", type: "checkbox", var: "r_21_2" },
        { label: "El papá es alto.", type: "checkbox", var: "r_22_1" },
        { label: "El papá está alto.*:", type: "checkbox", var: "r_22_2" },
        {
          label: "El niño es llamado por la niña.*:",
          type: "checkbox",
          var: "r_23_1",
        },
        {
          label: "La niña es llamada por el niño.",
          type: "checkbox",
          var: "r_23_2",
        },
        { label: "R obs:", type: "text", var: "r_obs", maxlength: "300" },
        {
          label: "La puerta no está cerrada.:",
          type: "checkbox",
          var: "e_1_1",
        },
        { label: "La puerta está cerrada.", type: "checkbox", var: "e_1_2" },
        {
          label: "El perro está encima del auto.*:",
          type: "checkbox",
          var: "e_2_1",
        },
        {
          label: "El perro está adentro del auto.",
          type: "checkbox",
          var: "e_2_2",
        },
        {
          label: "El gato está debajo de la silla.",
          type: "checkbox",
          var: "e_3_1",
        },
        {
          label: "El gato está detrás de la silla.*:",
          type: "checkbox",
          var: "e_3_2",
        },
        { label: "El ve al gato.*:", type: "checkbox", var: "e_4_1" },
        { label: "Ella ve al gato.", type: "checkbox", var: "e_4_2" },
        { label: "Alguien está en la silla.", type: "checkbox", var: "e_5_1" },
        { label: "Algo está en la silla.*:", type: "checkbox", var: "e_5_2" },
        { label: "El sombrero es de ella.", type: "checkbox", var: "e_6_1" },
        { label: "El sombrero es de él.*:", type: "checkbox", var: "e_6_2" },
        { label: "Está durmiendo.", type: "checkbox", var: "e_7_1" },
        { label: "Están durmiendo.*:", type: "checkbox", var: "e_7_2" },
        { label: "El niño se vistió.*:", type: "checkbox", var: "e_8_1" },
        { label: "El niño se viste.", type: "checkbox", var: "e_8_2" },
        {
          label: "La niña está escribiendo.*:",
          type: "checkbox",
          var: "e_9_1",
        },
        {
          label: "La niña estaba escribiendo.",
          type: "checkbox",
          var: "e_9_2",
        },
        { label: "La niña la ve.", type: "checkbox", var: "e_10_1" },
        { label: "La niña lo ve.*:", type: "checkbox", var: "e_10_2" },
        { label: "El niño tenía el globo.*:", type: "checkbox", var: "e_11_1" },
        { label: "El niño tiene el globo.", type: "checkbox", var: "e_11_2" },
        { label: "La niña lo lleva.*:", type: "checkbox", var: "e_12_1" },
        { label: "La niña los lleva.", type: "checkbox", var: "e_12_2" },
        { label: "Este es mi amigo.", type: "checkbox", var: "e_13_1" },
        { label: "Aquel es mi amigo.*:", type: "checkbox", var: "e_13_2" },
        { label: "El niño lo lava.", type: "checkbox", var: "e_14_1" },
        { label: "El niño se lava.*:", type: "checkbox", var: "e_14_2" },
        { label: "Este es su perro.*:", type: "checkbox", var: "e_15_1" },
        { label: "Este es nuestro perro.", type: "checkbox", var: "e_15_2" },
        { label: "La niña comió.*:", type: "checkbox", var: "e_16_1" },
        { label: "La niña comerá.", type: "checkbox", var: "e_16_2" },
        { label: "Esa es mi muñeca.", type: "checkbox", var: "e_17_1" },
        { label: "Esta es mi muñeca.*:", type: "checkbox", var: "e_17_2" },
        {
          label: "¿Quién está en la puerta?.*:",
          type: "checkbox",
          var: "e_18_1",
        },
        { label: "¿Qué está en la puerta?", type: "checkbox", var: "e_18_2" },
        { label: "¿Dónde está el niño?", type: "checkbox", var: "e_19_1" },
        { label: "¿Quién es el niño?.*:", type: "checkbox", var: "e_19_2" },
        {
          label: "El niño va a cortarse el pelo.",
          type: "checkbox",
          var: "e_20_1",
        },
        {
          label: "El niño fue a cortarse el pelo.*:",
          type: "checkbox",
          var: "e_20_2",
        },
        {
          label: "El niño dice, 'Me la dio'.*:",
          type: "checkbox",
          var: "e_21_1",
        },
        {
          label: "El niño dice, 'Se la dio'.",
          type: "checkbox",
          var: "e_21_2",
        },
        { label: "El niño es alto.", type: "checkbox", var: "e_22_1" },
        { label: "El niño está alto.*:", type: "checkbox", var: "e_22_2" },
        {
          label: "La niña es empujada por el niño.*:",
          type: "checkbox",
          var: "e_23_1",
        },
        {
          label: "El niño es empujado por la niña.",
          type: "checkbox",
          var: "e_23_2",
        },
        { label: "E obs:", type: "text", var: "e_obs", maxlength: "300" },
      ],
    };
  },
  methods: {
    validateForm() {
      if (!this.answer.answers.edad) {
        alert("Debes completar el campo de edad");
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validateForm()) {
        // Proceder con el envío del formulario
        // Aquí puedes agregar la lógica para enviar el formulario si es válido
      }
    },
  },
};
</script>
