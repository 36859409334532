<template>
  <div class="text-center w-100">
    <transition name="slide-fade" mode="out-in">
      <component
        :is="view"
        class="w-100"
        ref="main"
        :application="application"
        :selectedApplication="appID"
        :reviewResponseID="link.id"
        :selectedLink="link"
        :material="material"
        :selectedLevel="level"
        :selectedGrade="grade"
        :levels="levels"
        @review="goToReview"
        @back-to-application-admin="backToAdmin"
        @clear-material="$emit('clear-material')"
        @create-new-material="$emit('create-new-material')"
        @open-application-status="openApplication"
        @back-to-list="backToList"
        @response-admin="responseAdmin"
      ></component>
    </transition>
  </div>
</template>

<script>
// import customMaterialService from "@/services/customMaterialService";
import MaterialApplicationsAdmin from "@/components/custom-materials/miscellaneous/MaterialApplicationsAdmin";
import ApplicationListComponent from "@/components/custom-materials/miscellaneous/ApplicationListComponent";
import MaterialResponseReview from "@/components/custom-materials/miscellaneous/MaterialResponseReview";
import MaterialResponseAdmin from "@/components/custom-materials/miscellaneous/MaterialResponseAdmin";
import materialDocumentSocket from "@/mixins/materialDocumentSocket";
export default {
  name: "MaterialStatusIndex",
  props: ["material", "inApplication"],
  components: {
    ApplicationListComponent,
    MaterialApplicationsAdmin,
    MaterialResponseReview,
    MaterialResponseAdmin,
  },
  mixins: [materialDocumentSocket],
  data() {
    return {
      view: ApplicationListComponent,
      application: {},
      appID: null,
      link: {},
      level: 0,
      levels: {},
      grade: {},
    };
  },
  computed: {
    schoolID() {
      return this.$store.state.login.school;
    },
    userID() {
      return this.$store.state.login.user_id;
    },
  },
  mounted() {
    if (this.inApplication) {
      this.openApplication(this.inApplication);
    }
  },
  methods: {
    backToAdmin() {
      this.link = {};
      this.view = MaterialApplicationsAdmin;
    },
    responseAdmin(link, levels, level, grade) {
      this.link = link;
      this.levels = levels;
      // this.application = app;
      this.level = level;
      this.grade = grade;
      this.view = MaterialResponseAdmin;
    },
    goToReview(link) {
      this.link = link;
      this.$emit("response-admin", link, this.application);
    },
    openApplication(app) {
      this.application = app;
      this.appID = app.id;
      this.joinApplication(this.appID);
      this.view = MaterialApplicationsAdmin;
    },
    backToList() {
      this.appID = null;
      this.view = ApplicationListComponent;
    },
    createFromMaterialList(material) {
      this.view = ApplicationListComponent;
      this.$refs["main"].openFromMaterial(material);
    },
  },
};
</script>

<style>
.custom-bg-light {
  background-color: #f3f3f3;
}
.h-scroll {
  overflow-x: scroll;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
</style>
