<template>
  <b-row class="pr-1">
    <b-col cols="3">
      <b-button
        class="m-1"
        size="sm"
        style="color: black"
        variant="outline-light"
        block
        pill
      >
        <strong>{{ student.student_grade.student_name }}</strong>
      </b-button>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-dropdown variant="link" no-caret class="mt-2">
        <template v-slot:button-content>
          <div
            class="value-container"
            v-bind:class="getValueClasses(student.material_link)"
            :title="getTitle(student.material_link)"
          >
            <div>
              <v-icon
                class="icon"
                scale="0.9"
                :name="getValueIcon(student.material_link)"
              />
              <v-icon class="icon" scale="0.65" name="chevron-down" />
            </div>
          </div>
        </template>
        <b-dropdown-item
          href="#"
          v-if="!student.material_link.finished"
          @click="copyLink(student.material_link)"
        >
          <v-icon class="icon" scale="0.9" name="mouse-pointer" />
          COPIAR LINK
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          v-if="
            student.material_link.started && !student.material_link.finished
          "
          @click="closeLink(student.material_link)"
        >
          <v-icon class="icon" scale="0.9" name="download" />CERRAR
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          v-if="
            student.material_link.started && !student.material_link.finished
          "
          @click="closeAndReview(student.material_link)"
        >
          <v-icon class="icon" scale="0.9" name="download" />CERRAR Y REVISAR
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          v-if="student.material_link.finished"
          @click="review(student.material_link)"
        >
          <v-icon class="icon" scale="0.9" name="upload" />REVISAR
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="2" class="text-center p-0">
      <div class="mt-2">
        <strong>
          {{
            student.material_link.started
              ? student.questions_score.percent_score
              : "-"
          }}
          %
        </strong>
      </div>
    </b-col>
    <b-col cols="2" class="text-center p-0">
      <div
        v-if="student.material_link.started && achieved_tags.length"
        class="mt-2"
      >
        <b-badge
          v-for="(oa, index) in achieved_tags"
          :key="index"
          variant="info"
          class="text-wrap"
        >
          {{ oa.summary }}
        </b-badge>
      </div>
      <div v-else class="mt-2">
        <b-badge variant="info"> - </b-badge>
      </div>
    </b-col>
    <b-col cols="2" class="text-center p-0">
      <div
        v-if="student.material_link.started && moderate_achieved_tags.length"
        class="mt-2"
      >
        <b-badge
          v-for="(oa, index) in moderate_achieved_tags"
          :key="index"
          variant="primary"
          class="text-wrap"
        >
          {{ oa.summary }}
        </b-badge>
      </div>
      <div v-else class="mt-2">
        <b-badge variant="primary"> - </b-badge>
      </div>
    </b-col>
    <b-col cols="2" class="text-center p-0">
      <div
        v-if="student.material_link.started && unachieved_tags.length"
        class="mt-2"
      >
        <b-badge
          v-for="(oa, index) in unachieved_tags"
          :key="index"
          variant="danger"
          class="text-wrap"
        >
          {{ oa.summary }}
        </b-badge>
      </div>
      <div v-else class="mt-2">
        <b-badge variant="danger"> - </b-badge>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
import materialDocumentSocket from "@/mixins/materialDocumentSocket";
export default {
  name: "StudentResponseRow",
  props: {
    student: {
      type: Object,
    },
    level: {
      type: Object,
    },
    grade: {
      type: Object,
    },
  },
  mixins: [materialDocumentSocket],
  created() {
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data);
      if (message.type === "application_state_updated") {
        if (message.material_response.id == this.student.id) {
          this.student.material_link = message.material_response.material_link;
          this.student.questions_score =
            message.material_response.questions_score;
          console.log(message);
        }
      }
    };
  },
  computed: {
    achieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter((tag) => tag.score / tag.total_score >= 0.6);
    },
    unachieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter((tag) => tag.score / tag.total_score < 0.3);
    },
    moderate_achieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter(
        (tag) =>
          tag.score / tag.total_score >= 0.3 &&
          tag.score / tag.total_score < 0.6
      );
    },
  },
  methods: {
    getValueClasses(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return [
            "text-primary",
            "text-ultralight-hover",
            "bg-primary-hover",
            "border-primary",
          ];
        } else if (materialLink.finished && !materialLink.reviewed) {
          return [
            "text-secondary",
            "text-ultralight-hover",
            "bg-secondary-hover",
            "border-secondary",
          ];
        } else {
          return [
            "text-tertiary",
            "text-ultralight-hover",
            "bg-tertiary-hover",
            "border-tertiary",
          ];
        }
      } else {
        return [
          "text-redpie",
          "text-ultralight-hover",
          "bg-redpie-hover",
          "border-redpie",
        ];
      }
    },
    getTitle(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "NO TERMINADO";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "NO REVISADO";
        } else {
          return "REVISADO";
        }
      } else {
        return "SIN COMENZAR";
      }
    },
    getValueIcon(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "minus";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "check";
        } else {
          return "folder-open";
        }
      } else {
        return "times";
      }
    },
    copyLink(materialLink) {
      let testURL = `${process.env["VUE_APP_BASE_URL"]}/materials#/t/${materialLink.id}`;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(testURL);
      } else if (window.clipboardData) {
        window.clipboardData.setData("url", testURL);
      } else {
        this.$toasted.error("No se pudo copiar");
        return;
      }
      this.$toasted.success("Link copiado en el portapapeles");
    },
    closeLink(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          this.$toasted.success(data.message);
        });
    },
    closeAndReview(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          materialLink.finished = true;
          this.$toasted.success(data.message);
          this.review(materialLink);
        });
    },
    review(materialLink) {
      this.$emit("response-admin", materialLink, this.level, this.grade);
    },
  },
};
</script>

<style></style>
