import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  getStudentsWithoutTests(schoolID, year) {
    return api
      .get(`schools/${schoolID}/get_students_without_tests/?year=${year}`)
      .then((response) => response.data);
  },
  getFilters(selectedHashtag) {
    return api
      .get(`test-tags/get_filters/?hashtag=${selectedHashtag}`)
      .then((response) => response.data);
  },
  getTagsFiltered(hashtags) {
    return api
      .get(`test-tags/get_tags/?hashtags=${hashtags}`)
      .then((response) => response.data);
  },
  getHashtagsByContent(subject, grade) {
    return api
      .get(`test-tags/get_tags/?subject=${subject}&grade=${grade}`)
      .then((response) => response.data);
  },
  getSubtags() {
    return api
      .get("test-subtags/get_subtags/")
      .then((response) => response.data);
  },
  getStudentsNotClosed(schoolID, year) {
    return api
      .get(`schools/${schoolID}/get_students_not_closed/?year=${year}`)
      .then((response) => response.data);
  },
  getStudentsNotReviewed(schoolID, year) {
    return api
      .get(`schools/${schoolID}/get_students_not_reviewed/?year=${year}`)
      .then((response) => response.data);
  },
  getStudentsReviewed(schoolID, year) {
    return api
      .get(`schools/${schoolID}/get_students_reviewed/?year=${year}`)
      .then((response) => response.data);
  },
  getSchoolTests(schoolID) {
    return api
      .get(`schools/${schoolID}/get_tests/`)
      .then((response) => response.data);
  },
  get_tests() {
    return api.get("custom-tests/").then((response) => response.data);
  },
  get_paginated_tests(page, per_page, order) {
    return api
      .get(
        `custom-tests/paginated_tests/?page=${page}&per_page=${per_page}&order=${order}`
      )
      .then((response) => response.data);
  },
  get_test(testID) {
    return api.get(`custom-tests/${testID}/`).then((response) => response.data);
  },
  get_texts() {
    return api.get("texts/").then((response) => response.data);
  },
  getText(id) {
    return api.get(`texts/${id}/`).then((response) => response.data);
  },
  updateText(text) {
    return api
      .patch(`texts/${text.id}/`, text)
      .then((response) => response.data);
  },
  createText(payload) {
    return api.post("texts/", payload).then((response) => response.data);
  },
  createTest(test) {
    return api.post("custom-tests/", test).then((response) => response.data);
  },
  getEurekaSchools(page, name) {
    return api
      .get(`schools/get_eureka_schools/?page=${page}&name=${name}`)
      .then((response) => response.data);
  },
  asociateEureka(payload) {
    return api
      .post("custom-tests/asociate_eureka/", payload)
      .then((response) => response.data);
  },
  associateTestSchool(payload) {
    return api
      .post("custom-tests/associate_to_school/", payload)
      .then((response) => response.data);
  },
  getGradeTests(testID, schoolID) {
    return api
      .get(`custom-tests/${testID}/grades/?school=${schoolID}`)
      .then((response) => response.data);
  },
  updateTest(test) {
    return api
      .patch(`custom-tests/${test.id}/`, test)
      .then((response) => response.data);
  },
  deleteTest(testID) {
    return api
      .delete(`custom-tests/${testID}/`)
      .then((response) => response.data);
  },
  deleteText(textID) {
    return api.delete(`texts/${textID}/`).then((response) => response.data);
  },
  sendLinkToStudent(id) {
    return api
      .get(`test-links/${id}/send-link/`)
      .then((response) => response.data);
  },
  generateLink(test) {
    return api.post("test-links/", test).then((response) => response.data);
  },
  generateBulkInvites(payload) {
    return api
      .post("test-links/bulk/", payload)
      .then((response) => response.data);
  },
  get_test_link(testID) {
    return api.get(`test-links/${testID}/`).then((response) => response.data);
  },
  closeTest(payload) {
    return api
      .post("test-links/close_test/", payload)
      .then((response) => response.data);
  },
  returnStage(payload) {
    return api
      .post("test-links/return_stage/", payload)
      .then((response) => response.data);
  },
  submitAnswer(formData) {
    return multiform
      .post("test-responses/", formData)
      .then((response) => response.data);
  },
  getInvites(testID) {
    return api
      .get(`test-links/test/?id=${testID}`)
      .then((response) => response.data);
  },
  getQuestionAnswer(inviteID, questionID) {
    return api
      .get(`test-responses/question/?invite=${inviteID}&question=${questionID}`)
      .then((response) => response.data);
  },
  getAnswers(testID) {
    return api
      .get(`test-responses/test/?id=${testID}`)
      .then((response) => response.data);
  },
  uploadFiles(formData) {
    return multiform
      .post("custom-tests/files/", formData)
      .then((response) => response.data);
  },
  testStarted(inviteID, payload) {
    return api
      .post(`test-links/${inviteID}/start/`, payload)
      .then((response) => response.data);
  },
  getTest(testID) {
    return api.get(`custom-tests/${testID}/`).then((response) => response.data);
  },
  evaluateTest(data) {
    return api
      .post("test-responses/evaluate/", data)
      .then((response) => response.data);
  },
  redoTest(id) {
    return api
      .get(`test-responses/redo/?id=${id}`)
      .then((response) => response.data);
  },
  continueTest(id) {
    return api
      .get(`test-responses/continue/?id=${id}`)
      .then((response) => response.data);
  },
  getResponses(inviteID) {
    return api
      .get(`test-responses/invite/?id=${inviteID}`)
      .then((response) => response.data);
  },
  createDictation(formData) {
    return multiform
      .post("custom-tests/dictation/", formData)
      .then((response) => response.data);
  },
  optionFiles(formData) {
    return multiform
      .post("custom-tests/options/", formData)
      .then((response) => response.data);
  },
  saveAdditionalInstruction(formData) {
    return multiform
      .post("custom-tests/save_additional_instruction/", formData)
      .then((response) => response.data);
  },
  addTextAudio(formdata) {
    return multiform
      .post("texts/audio/", formdata)
      .then((response) => response.data);
  },
  deleteResponses(student) {
    return api
      .delete(`test-responses/delete/${student}/`)
      .then((response) => response.data);
  },
  checkAnswers(inviteID) {
    return api
      .get(`test-links/${inviteID}/check_answers/`)
      .then((response) => response.data);
  },
  updatePageResponse(inviteID, payload) {
    return api
      .post(`test-links/${inviteID}/update_page_response/`, payload)
      .then((response) => response.data);
  },
  saveEvents(inviteID, payload) {
    return api
      .post(`test-links/${inviteID}/save_evaluation_events/`, payload)
      .then((response) => response.data);
  },
  createTag(payload) {
    return api.post("test-tags/", payload).then((response) => response.data);
  },
  createSubtag(tagID, payload) {
    return api
      .post(`test-tags/${tagID}/create_subtag/`, payload)
      .then((response) => response.data);
  },
  getTags() {
    return api.get("test-tags/").then((response) => response.data);
  },
  associateTags(payload) {
    return api
      .post("test-tags/associate_tags/", payload)
      .then((response) => response.data);
  },
  saveRubrics(payload) {
    return api
      .post("test-rubrics/create_rubric/", payload)
      .then((response) => response.data);
  },
  getGrades(schoolID, year) {
    return api
      .get(`schools/${schoolID}/grades/?year=${year}`)
      .then((response) => response.data);
  },
  createCSV(payload) {
    return api
      .post("custom-tests/create_csv/", payload)
      .then((response) => response.data);
  },
  createReport(payload) {
    return api
      .post("schools/new-create-eureka-report/", payload)
      .then((response) => response.data);
  },
  checkTestRut(rut, year, showPast) {
    return api
      .get(
        `schools/check-test-rut/?rut=${rut}&year=${year}&use_previous=${showPast}`
      )
      .then((response) => response.data);
  },
  savePageAnswers(inviteID, payload) {
    return api
      .post(`test-links/${inviteID}/save_page_answers/`, payload)
      .then((response) => response.data);
  },
  getPreviousAnswers(invitationID, pageID) {
    return api
      .get(
        `test-responses/get-previous-answers/?invitation=${invitationID}&page=${pageID}`
      )
      .then((response) => response.data);
  },
  getEurekaAchievements(studentID, gradeID) {
    return api.get(`custom-tests/get_eureka_achievement/?student_id=${studentID}&grade_id=${gradeID}`).then((response) => response.data);
  },
  getStudentsEurekaStatus(gradeID) {
    return api.get(`schools/get-students-eureka-status/?grade_id=${gradeID}`).then((response) => response.data);
  },
  getSingleStudentEurekaStatus(studentGradeID) {
    return api.get(`schools/get-single-student-eureka-status/?student_grade_id=${studentGradeID}`).then((response) => response.data);
  },
  getTests() {
    return api.get('custom-tests/get_tests').then((response) => response.data);
  },
  downloadEurekaReport(payload) {
    return api
            .post('schools/download_eureka_report/', payload, { responseType: "blob" })
            .then((response) => response);
  },
  downloadEurekaProtocol(payload) {
    return api
            .post('schools/download_eureka_protocol/', payload, { responseType: "blob" })
            .then((response) => response);
  },
  downloadEurekaTemplate(payload) {
    return api
            .post('schools/download_eureka_template/', payload, { responseType: "blob" })
            .then((response) => response);
  },
  downloadWritingTemplate(params) {
    return api.get('custom-tests/download_writing_template/', {params: params, responseType: "blob"}).then((response) => response);
  }
};
