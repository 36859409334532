<template>
    <div>
        <b-modal size="sm" no-close-on-backdrop no-close-on-esc hide-footer hide-header ref="loading-modal">
            <div class="w-100 text-center">
                <b-spinner variant="info" label="Spinning" />
            </div>
        </b-modal>
        <b-container v-if="mode==='admin' && !studentGradeID">
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-for="(grade, gIndex) in grades" :key="gIndex+'-g'">
                    <b-card-header header-tag="header" class="text-left" role="tab">
                        <b-button block @click="visible===grade.id?visible='':visible=grade.id" variant="primary" pill>{{ grade.rename? grade.rename:grade.name }}</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-'+gIndex" :visible="grades.length===1 || visible===grade.id" @show="getStudents(grade.id)" accordion="my-accordion" role="tabpanel">
                        <b-container v-if="!students[grade.id]">
                            <b-row>
                                <b-col class="text-center">
                                    <b-spinner
                                        style="width: 3rem; height: 3rem"
                                        type="grow"
                                        label="Loading..."
                                    ></b-spinner>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-table-simple
                            v-else
                            class="plan-table"
                            hover
                            small
                            caption-top
                            responsive
                            bordered 
                        >
                            <b-tbody>
                                <b-tr>
                                    <b-th>Nombre Estudiante</b-th>
                                    <b-th>Nivel de Aplicación</b-th>
                                    <b-th>Matemáticas</b-th>
                                    <b-th>Lenguaje</b-th>
                                    <b-th>Descargar informe</b-th>
                                    <b-th>Descargar plantillas</b-th>
                                </b-tr>
                                <b-tr v-for="(student, sIndex) in students[grade.id]" :key="sIndex+'-s'">
                                    <b-td>{{ student.student_name }}</b-td>
                                    <b-td>
                                        <b-select v-model="student.last_eureka_level">
                                            <b-select-option v-for="(level, lIndex) in levels" :key="lIndex+'-l'" :value="level[0]"
                                                :style="student.tests.filter(test => test.reviewed && test.test_level===level[0]).length? 'background-color: #90EE90':student.tests.filter(test => test.started && test.test_level===level[0]).length? 'background-color: #DAA520':'background-color: white'"
                                            >
                                                <div>
                                                    {{ level[1] }}
                                                </div>
                                            </b-select-option>
                                        </b-select>
                                    </b-td>
                                    <b-td>
                                        <eureka-table-button :test="getTest(student.tests, 7, student.last_eureka_level, student)" @response-admin="review" @reload="getStudents(grade.id)" :download="download"/>
                                    </b-td>
                                    <b-td>
                                        <eureka-table-button :test="getTest(student.tests, 6, student.last_eureka_level, student)" @response-admin="review" @reload="getStudents(grade.id)" :download="download"/>
                                    </b-td>
                                    <b-td>
                                        <div class="text-center my-2">
                                            <div v-if="!download">
                                                <b-button pill variant="primary" size="sm" @click="generateReport(student.id, student.last_eureka_level)" v-if="student.tests.filter(test => test.reviewed && test.test_level===student.last_eureka_level).length">
                                                    Generar Informe
                                                </b-button>
                                                <div v-else class="text-center" style="max-width: 200px; margin: auto">
                                                    Debes revisar y cerrar al menos una evaluación del nivel seleccionado para poder descargar el informe.
                                                </div>
                                            </div>
                                            <b-row v-else>
                                                <b-col cols="9">
                                                    <b-select v-model="student.download_status">
                                                        <b-select-option :value="0">
                                                            Borrador de Aplicación
                                                        </b-select-option>
                                                        <b-select-option :value="1" v-if="student.tests.filter(test => test.reviewed && test.test_level===student.last_eureka_level).length">
                                                            Informe Psicopedagógico
                                                        </b-select-option>
                                                        <b-select-option :value="2" v-if="student.tests.filter(test => test.reviewed && test.test_level===student.last_eureka_level).length">
                                                            Informe Protocolo
                                                        </b-select-option>
                                                        <b-select-option :value="3" v-if="student.tests.filter(test => test.reviewed && test.test_level===student.last_eureka_level).length">
                                                            Plantilla de Pruebas
                                                        </b-select-option>
                                                    </b-select>
                                                </b-col>
                                                <b-col cols="3" class="pl-0 text-left" @click="downloadSelected(student.download_status, student.id, student.last_eureka_level)">
                                                    <b-img width="30em" :src="downloadIcon" style="cursor: pointer"/>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div class="text-center my-2">
                                            <b-button pill variant="primary" size="sm" @click="downloadWritingTemplate(student.last_eureka_level)">
                                                Descargar Plantilla
                                            </b-button>
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-collapse>
                </b-card>
            </div>
        </b-container>
        <div v-if="studentGradeID && singleStudent.tests">
            <b-table-simple
                class="plan-table"
                hover
                small
                caption-top
                responsive
                bordered 
            >
                <b-tbody>
                    <b-tr>
                        <b-th>Nombre Estudiante</b-th>
                        <b-th>Nivel de Aplicación</b-th>
                        <b-th>Matemáticas</b-th>
                        <b-th>Lenguaje</b-th>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ singleStudent.student_name }}</b-td>
                        <b-td>
                            <b-select v-model="singleStudent.last_eureka_level" disabled>
                                <b-select-option v-for="(level, lIndex) in levels" :key="lIndex+'-l'" :value="level[0]"
                                    :style="singleStudent.tests.filter(test => test.reviewed && test.test_level===level[0]).length? 'background-color: #90EE90':singleStudent.tests.filter(test => test.started && test.test_level===level[0]).length? 'background-color: #DAA520':'background-color: white'"
                                >
                                    <div>
                                        {{ level[1] }}
                                    </div>
                                </b-select-option>
                            </b-select>
                        </b-td>
                        <b-td>
                            <b-btn variant="primary" pill 
                                @click="goToTest(getTest(singleStudent.tests, 7, singleStudent.last_eureka_level, singleStudent))"
                            >
                                Ir a la Prueba
                            </b-btn>
                        </b-td>
                        <b-td>
                            <b-btn variant="primary" pill 
                                @click="goToTest(getTest(singleStudent.tests, 6, singleStudent.last_eureka_level, singleStudent))"
                            >
                                Ir a la Prueba
                            </b-btn>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <div v-if="mode==='review'">
            <test-response-review :reviewResponseID="reviewTest.id" :selectedLink="reviewTest" @back="backToAdmin"/>
        </div>
        <div v-if="mode==='new'">
            <b-container>
                <b-row>
                    <b-col class="text-left">
                        <h3>Con qué nivel trabajas usualmente?</h3>
                    </b-col>
                </b-row>
                <b-row class="my-2">
                    <b-col class="text-center">
                        <h4>Enseñanza Básica</h4>
                        <b-button v-for="(gr, grIndex) in separatedLevels[1].levels" :key="grIndex+'-gr'" 
                            pill variant="primary" class="mx-1" @click="createEurekaBasicSchool(gr.code)"
                        >
                            {{ gr.name.split(' ')[0] }}
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <h4>Enseñanza Media</h4>
                        <b-button v-for="(gr, grIndex) in separatedLevels[2].levels" :key="grIndex+'-gr'" 
                            pill variant="primary" class="mx-1" @click="createEurekaBasicSchool(gr.code)"
                        >
                            {{ highNameChange[gr.name.split(' ')[0]] }}
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <div class="d-block w-100">
                    Al seleccionar un curso crearemos para ti un colegio de prueba con 3 estudiantes.<br>
                    Con estos estudiantes podrás:<br>
                    </div>
                    <ol class="d-block mt-2">
                        <li>
                            <b-img width="20em" :src="copyIcon"/>
                            Copiar el link para realizar una evaluación
                        </li>
                        <li>
                            <b-img width="20em" :src="reviewIcon"/>
                            Revisar una evaluación.
                        </li>
                        <li>
                            <b-img width="20em" :src="readyIcon"/>
                            Revisar un informe, que dejamos terminado.
                        </li>
                        <li>
                            <b-img width="20em" :src="downloadIcon"/>
                            Descargar un informe completo.
                        </li>
                    </ol>
                </b-row>
            </b-container>
        </div>
        <div v-if="mode==='loading'" class="text-center">
            <b-spinner variant="info" label="Spinning" />
        </div>
        <div v-if="mode==='admin' && download">
            <div class="d-block" v-if="false">
                <b-carousel
                    id="carousel-1"
                    :interval="10000"
                    controls
                    indicators
                    background="white"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                >
                    <b-carousel-slide v-for="(image, iIndex) in images" :key="iIndex" :img-src="image.img" img-height="480" img-width="1024">
                        <h1 class="text-dark"></h1>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <div v-else class="d-block text-center">
                <b-img v-for="(image, iIndex) in images" :key="iIndex" :src="image.img" class="d-block" fluid-grow/>
            </div>
        </div>
    </div>
</template>
<script>
import customTestService from '../../../services/customTestService'
import redpieService from '../../../services/redpieService'
import informationService from '../../../services/informationService'
import EurekaTableButton from './EurekaTableButton'
import TestResponseReview from '../../custom-tests/miscellaneous/TestResponseReview'

export default {
    components: {
        EurekaTableButton,
        TestResponseReview
    },
    props: {
        studentGradeID: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            grades: [],
            students: {},
            levels: [],
            separatedLevels: [],
            highNameChange: {
                "1°": "I°",
                "2°": "II°",
                "3°": "III°",
                "4°": "IV°",
            },
            test: [],
            visible: "",
            mode: 'admin',
            download: false,
            downloadIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_descargar.svg'),
            copyIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_link.svg'),
            continueIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_terminar prueba.svg'),
            reviewIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_revisar.svg'),
            readyIcon: require('../../../assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_listo.svg'),
            reviewTest: null,
            eurekaLevel: null,
            images: [
                {
                    name: "Aplicar Eureka",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_4.png')
                },
                {
                    name: "Interrumpir una Evaluación",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_5.png')
                },
                {
                    name: "Corregir una Evaluación",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_6.png')
                },
                {
                    name: "Corregir una Evaluación",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_7.png')
                },
                {
                    name: "Generar Informe",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_8.png')
                },
                {
                    name: "Consideraciones especiales",
                    img: require('../../../assets/img/eureka table sections/Front Eureka_9.png')
                },
            ],
            singleStudent: {}
        }
    },
    created() {
        this.getLevels();
        this.getTests();
    },
    methods: {
        getSingleData() {
            customTestService.getSingleStudentEurekaStatus(this.studentGradeID).then(
                data => {
                    // this.$set(this.students, gradeID, data);
                    this.singleStudent = data;
                }
            )
        },
        startInNew() {
            this.mode = 'new';
        },
        downloadSelected(selected, studentID, level) {
            if (selected===1) {
                this.downloadReport(studentID, level)
            }
            if (selected===2) {
                this.downloadProtocol(studentID, level)
            }
            if (selected===3) {
                this.downloadTemplate(studentID, level)
            }
        },
        generateLink(test) {
            customTestService.generateLink({
                test: test.test_id,
                rut: test.student_rut,
                email: test.student_email,
                name: test.student_name,
                videocall_kind: 3,
                student_grade: test.student_grade
            }).then(
                data => {
                    test.started = true;
                    let testURL = `${process.env['VUE_APP_BASE_URL']}/tests#/t/${data.id}`
                    window.open(testURL, '_blank').focus();
                },
                () => this.$toasted.error("Ocurrió un error. Intenta nuevamente.")
            )
        },
        goToTest(test) {
            if (test.started || test.id) {
                let testURL = `${process.env["VUE_APP_BASE_URL"]}/tests#/t/${test.id}`;
                window.open(testURL, '_blank').focus();
            }
            else {
                this.generateLink(test);
            }
        },
        getLevels() {
            informationService.getPieGrades().then(
                data => {
                    this.separatedLevels = data.grades_by_level;
                    this.levels = data.grades.filter(grade => grade[0]>1);
                }
            )
        },
        downloadWritingTemplate(level) {
            customTestService.downloadWritingTemplate({level: level}).then(
                (response) => {
                    var filename = "";
                    var disposition = response.headers["content-disposition"];
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                    var blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                }
            ) 
        },
        getTests() {
            customTestService.getTests().then(
                data => {
                    this.tests = data.filter(test => test.use_audio);
                    if (!this.studentGradeID) {
                        this.getGrades();
                    }
                    else {
                        this.getSingleData();
                    }
                }
            )
        },
        getTest(tests, subject, level, student) {
            try {
                if (tests.length && tests.filter(test => test.test_subject===subject && test.test_level===level).length) {
                    return tests.filter(test => test.test_subject===subject && test.test_level===level)[0]
                }
                else {
                    var test = this.tests.filter(test => test.subject===subject && test.level===level)[0]
                    var d = {
                        student_rut: student.student_rut,
                        student_name: student.student_name,
                        student_email: student.student_email,
                        student_grade: student.id
                    }
                    if (test) {
                        d["test_id"] = test.id
                    }
                    return d
                }
            }
            catch(error) {
                console.log(error);
            }
        },
        getGrades() {
            if (this.$store.state.login.school) {
                redpieService.getGradesByYear(this.$store.state.login.year? this.$store.state.login.year:(new Date()).getFullYear(), this.$store.state.login.school).then(
                    data => {
                        this.grades = data.filter(grade => grade.grade>1);
                        if (this.grades.length===1) {
                            this.getStudents(this.grades[0].id);
                            this.visible = this.grades[0].id;
                        }
                    }
                )
            }
        },
        createEurekaBasicSchool(level) {
            this.mode = "loading"
            if (!level){
                redpieService.createEurekaBasicSchool({level: this.eurekaLevel}).then(
                    data => {
                        this.download = true;
                        this.grades = [data];
                        this.mode = "admin";
                        this.getStudents(data.id);
                        this.visible = data.id;
                    }
                )
            }
            else{
                redpieService.createEurekaBasicSchool({level: level}).then(
                    data => {
                        this.download = true;
                        this.grades = [data];
                        this.mode = "admin";
                        this.getStudents(data.id);
                        this.visible = data.id;
                    }
                )
            }
        },
        downloadReport(sgID, level) {
            this.openLoading()
            customTestService.downloadEurekaReport({student_grade: sgID, level: level}).then(
                (response) => {
                    var filename = "";
                    var disposition = response.headers["content-disposition"];
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                    var blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    this.closeLoading();
                }
            )
        },
        downloadProtocol(sgID, level) {
            this.openLoading();
            customTestService.downloadEurekaProtocol({student_grade: sgID, level: level}).then(
                (response) => {
                    var filename = "";
                    var disposition = response.headers["content-disposition"];
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                    var blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    this.closeLoading();
                }
            )
        },
        downloadTemplate(sgID, level) {
            customTestService.downloadEurekaTemplate({student_grade: sgID, level: level}).then(
                (response) => {
                    var filename = "";
                    var disposition = response.headers["content-disposition"];
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                    var blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                }
            )
        },
        openLoading() {
            this.$refs["loading-modal"].show();
        },
        closeLoading() {
            this.$refs["loading-modal"].hide();
        },
        getStudents(gradeID) {
            customTestService.getStudentsEurekaStatus(gradeID).then(
                data => {
                    this.$set(this.students, gradeID, data);
                }
            )
        },
        review(test) {
            this.reviewTest = test;
            this.mode = 'review';
        },
        backToAdmin() {
            this.getStudents(this.visible);
            window.scrollTo(0, 0);
            this.reviewTest = null;
            this.mode = 'admin';
        },
        generateReport(sgID, level) {
            customTestService.createReport({student_grade: sgID, level: level}).then(
                () => {
                    this.$toasted.success("Informes generados!")
                }
            )
        }
    },
}
</script>
<style lang="scss">
.value-container {
  cursor: pointer;
  margin: 0 auto;
  width: 56px;
  height: 24px;
  background-color: white;
  border: solid 1px;
  border-radius: 16px;
  font-size: 0.5em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>