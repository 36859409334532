<template>
  <div>
    <b-row>
      <b-col v-if="mode === 'tests'">
        <!-- <span>Tipo de Test</span> -->
        <b-select v-model="test_kind">
          <b-form-select-option value="a">Fonoaudiológico</b-form-select-option>
          <b-form-select-option value="b">Psicológico</b-form-select-option>
          <b-form-select-option value="c">Psicopedagógico</b-form-select-option>
          <b-form-select-option value="d">Terapia ocupacional</b-form-select-option>
        </b-select>
      </b-col>
      <b-col v-if="mode === 'tests'">
        <!-- {{ grade }} -->
        <b-select
          v-model="semester"
          @change="
            () => {
              grade = {};
              student = {};
              getGrades();
            }
          "
        >
          <b-form-select-option :value="1">1er Semestre</b-form-select-option>
          <b-form-select-option :value="2">2do Semestre</b-form-select-option>
        </b-select>
      </b-col>
      <b-col>
        <!-- <span>Curso</span> -->
        <b-select
          v-model="grade"
          @change="student = {}"
          :state="grade.id !== undefined"
        >
          <b-form-select-option :value="{}"
            >Selecciona curso</b-form-select-option
          >
          <b-form-select-option
            v-for="(grade, index) in grades"
            :key="index"
            :value="grade"
            >{{ grade.name }}</b-form-select-option
          >
        </b-select>
      </b-col>
      <b-col>
        <!-- <span>Estudiante</span> -->
        <b-select
          v-model="student"
          @change="getStudentDocuments"
          :state="student.id !== undefined"
        >
          <b-form-select-option :value="{}"
            >Selecciona estudiante</b-form-select-option
          >
          <b-form-select-option
            v-for="(student, index2) in grade.students"
            :key="index2"
            :value="student"
            >{{ student.name }}</b-form-select-option
          >
        </b-select>
      </b-col>
    </b-row>
    <Tests
      ref="tests"
      v-if="!loading"
      :class="mode !== 'tests' ? 'd-none' : ''"
      :student="student"
      :test_kind="test_kind"
      :tests="student.tests"
      :semester="semester"
      :documents="student_documents"
      :preselected_test="preselected_test"
      @open-templates="open_templates"
      @update-test-kind="update_test_kind"
      @delete-test="delete_test"
      @update-document="update_document"
    />
    <Documents
      ref="documents"
      v-if="!loading"
      :class="mode !== 'documents' ? 'd-none' : ''"
      :tests="student.tests"
      :documents="student_documents"
      :student="student"
      @open-loading="$emit('open-loading')"
      @show-fudei="show_fudei"
      @delete-document="delete_document"
      @create-fudei="create_fudei"
      @restore-document="restore_document"
      @show-modal-upload-document="show_modal_upload_document"
      @view-document="view_document"
      @archived-document="archived_document"
      @documents-mode="documents_mode"
      @test-mode="test_mode"
    />
    <b-row v-if="loading" class="text-md-center">
      <b-col md="12" class="mt-3 mb-3">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          type="grow"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Tests from "@/components/redpie/miscellaneous/document-tests/Tests";
import Documents from "@/components/redpie/miscellaneous/document-tests/Documents";
import redpieService from "../../../../services/redpieService";

export default {
  components: {
    Documents,
    Tests,
  },
  props: ["school", "year", "mode", "selected_student", "selected_grade"],
  data() {
    return {
      grades: [],
      grade: {},
      student: {},
      student_documents: [],
      loading: false,
      test_kind: "a",
      preselected_test: 0,
      semester: 1,
    };
  },
  created() {
    console.log(new Date().getMonth());
    if (new Date().getMonth() >= 6) {
      this.semester = 2;
    }
  },
  mounted() {
    this.getGrades();
  },
  watch: {
    school() {
      this.getGrades();
    },
    year() {
      this.getGrades();
    },
  },
  methods: {
    getGrades: function () {
      this.loading = true;
      redpieService
        .getGradesStudents(
          this.$store.state.login.school,
          this.$store.state.login.year,
          this.semester
        )
        .then(
          (data) => {
            this.grades = data;
            this.loading = false;
            if (this.selected_grade) {
              this.grade = this.grades.find((g) => g.id == this.selected_grade);
              this.student = this.grade.students.find(
                (s) => s.id == this.selected_student
              );
              this.getStudentDocuments();
            }
            if (this.$route.path === "/tutorial-docs") {
              this.grade = this.grades[0];
              this.student = this.grade.students[0];
              this.getStudentDocuments();
            }
          },
          () => {
            this.$toasted.error("Ups! Algo falló.");
            this.loading = false;
          }
        );
    },
    getStudentDocuments: function () {
      this.loading = true;
      if (this.student.id) {
        redpieService.getStudentGradeDocuments(this.student.id).then((data) => {
          this.student_documents = data;
          this.loading = false;
        });
      } else {
        this.student_documents = [];
        this.loading = false;
      }
    },
    show_modal_upload_document: function (data) {
      this.$emit("show-modal-upload-document", data);
    },
    show_fudei: function (fudei_id, sgdt_id, state) {
      this.$emit("show-fudei", fudei_id, sgdt_id, state);
    },
    create_fudei: function (sgdt_id) {
      this.$emit("create-fudei", sgdt_id);
    },
    view_document: function (data) {
      this.$emit("view-document", data);
    },
    download_document: function (data) {
      this.$emit("download-document", data);
    },
    archived_document: function (data) {
      this.$emit("archived-document", data);
    },
    delete_document: function (data) {
      this.$emit("delete-document", data);
    },
    restore_document: function (data) {
      this.$emit("restore-document", data);
    },
    open_templates: function (id) {
      this.$refs["documents"].openTemplate(`doc_${id}`);
    },
    documents_mode: function () {
      this.mode = "documents";
    },
    update_test_kind: function (kind) {
      this.test_kind = kind;
    },
    test_mode: function (test) {
      this.mode = "tests";
      this.$refs["tests"].doTest(test);
    },
    delete_test: function (test_id) {
      this.student.tests = this.student.tests.filter(
        (elem) => elem.redpsi_id != test_id
      );
    },
    update_document: function (preselected) {
      console.log(preselected);
      this.preselected_test = preselected;
      this.getStudentDocuments();
    },
  },
};
</script>
<style lang="scss"></style>
