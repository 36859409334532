import api from "@/services/api";

export default {
  generatePlots(grade, type, theme, details) {
    console.log("generate plots")
    const params = new URLSearchParams({
      grade: grade,
      type: type,
      theme: theme,
      details: details
    }).toString();
    return fetch(`/api/get_plots/?${params}`).then((response) => {
      return response.body;
    });
  },
  generateCharacters(n, plot, type) {
    const params = new URLSearchParams({
      n: n,
      plot: plot,
      type: type
    })
    return fetch(`/api/get_characters/?${params}`).then((response) => {
      return response.body;
    });
  },
  generateText(characters, plot, theme, nperson, type, details, age, style) {
    const params = new URLSearchParams({
      characters: characters,
      plot: plot,
      theme: theme,
      nperson: nperson,
      type: type,
      details: details,
      age: age,
      style: style
    });
    return fetch(`/api/get_text/?${params}`).then((response) => {
      return response.body;
    });
  },
  saveText(payload) {
    return api.post('save_text/', payload).then(response => response.data);
  },
  // generateQuestion(payload) {
  //   return api.post('generate_question/', payload).then(response => response.data);
  // },
  // generateMathQuestion(payload) {
  //   return api.post('generate_math_question/', payload).then(response => response.data);
  // },
  generateMathQuestion(question, school) {
    const params = new URLSearchParams({
      type: question.type,
      age: question.age,
      difficulty: question.difficulty,
      oa_id: question.oa.id,
      oa_description: question.oa.description,
      oa_name: question.oa.name,
      school: school
    });
    return fetch(`/api/generate_math_question/?${params}`,).then((response) => {
      return response;
    });
  },
  generateQuestion(text, question, school) {
    const params = new URLSearchParams({
      text: text,
      type: question.type,
      age: question.age,
      difficulty: question.difficulty,
      oa_id: question.oa.id,
      oa_description: question.oa.description,
      school: school
    });
    console.log("Antes de enviar")
    return fetch(`/api/generate_question/?${params}`).then((response) => {
      return response.body;
    });
  },
  generateHistory(payload) {
    return api.post('generate_history/', payload).then(response => response.data);
    // return fetch(`/api/generate_history/?${params}`).then((response) => {
    //   return response.body;
    // });
  },
  processStream(reader, decoder, page, vue_context) {
    return reader.read().then(
      function processChunk({done, value}) {
        if (value) {
          let decoded = decoder.decode(value);
          if (decoded.includes("[complete:")) {
            // sacar el question y pushear
            let stringWithoutBrackets = decoded.slice(10, -1);
            const q = JSON.parse(stringWithoutBrackets);
            // page.questions[index] = q;
            page.questions.pop();
            page.questions.push(q);
          }
          else {
            let splitDecoded = decoded.split(";");
            let chunks = splitDecoded.slice(0, -1);
            let index = page.questions.findIndex(q => q.question.includes('Generando pregunta'));
            chunks.forEach((item) => {
              if (item.includes("ST ")) {
                let text = item.slice(3);
                page.questions[index].question += text;
              }
              else if (item.includes("OA ")) {
                let text = item.slice(3);
                page.questions[index].tag.name = text;
              }
              else if (item.includes("ALT ")) {
                let text = item.slice(4);
                if (text.includes("A)")) {
                  text = text.slice(3);
                  page.questions[index].alternatives[0].text += text;
                }
                else if (text.includes("B)")) {
                  text = text.slice(3);
                  page.questions[index].alternatives[1].text += text;
                }
                else if (text.includes("C)")) {
                  text = text.slice(3);
                  page.questions[index].alternatives[2].text += text;
                }
                else {
                  text = text.slice(3);
                  page.questions[index].alternatives[3].text += text;
                }
              }
            })
          }
        }
        if (done) {
          vue_context.$emit("tag-completed", vue_context.planification);
          return;
        }
        return reader.read().then(processChunk);
      }
    )
  }
}