<template lang="">
  <div class="container">
    <h1>Prueba ICAP2</h1>

    <div v-for="section in test" :key="section.label" class="section">
      <h3>{{ section.label }}</h3>
      <div
        v-for="question in section.questions"
        :key="question.var"
        class="question"
      >
        <label :for="question.var">{{ question.label }}</label>
        <input
          type="number"
          :id="question.var"
          v-model="answer.answers[question.var]"
          :min="question.min"
          :max="question.max"
          :required="question.required"
          class="input-field"
        />
      </div>
    </div>

    <button @click="send_test" class="submit-btn">Enviar</button>
  </div>
</template>

<script>
import redpieService from "../../../../services/redpieService";
import testService from "../../../../services/testService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.answer.answers[key] = value;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: this.defaultAnswers(),
        student_id: this.student_id,
      },
      // Estructura de la prueba con ítems y números de preguntas
      test: [
        {
          label: "Destrezas Motoras (DM)",
          questions: Array.from({ length: 7 }, (_, i) => ({
            var: `dm_item${i + 1}`,
            label: `Item ${i + 1}`,
            type: "number",
            min: 0,
            max: 3,
            required: true,
          })),
        },
        {
          label: "Destrezas Sociales y Comunicativas (DSC)",
          questions: Array.from({ length: 5 }, (_, i) => ({
            var: `dsc_item${i + 1}`,
            label: `Item ${i + 1}`,
            type: "number",
            min: 0,
            max: 3,
            required: true,
          })),
        },
        {
          label: "Destrezas de la Vida Personal (DVP)",
          questions: Array.from({ length: 5 }, (_, i) => ({
            var: `dvp_item${i + 1}`,
            label: `Item ${i + 1}`,
            type: "number",
            min: 0,
            max: 3,
            required: true,
          })),
        },
        {
          label: "Destrezas de la Vida Diaria (DVD)",
          questions: Array.from({ length: 10 }, (_, i) => ({
            var: `dvd_item${i + 1}`,
            label: `Item ${i + 1}`,
            type: "number",
            min: 0,
            max: 3,
            required: true,
          })),
        },
      ],
    };
  },
  methods: {
    defaultAnswers() {
      let answers = {};
      // Inicializamos todos los campos con valor 0 por defecto
      for (let i = 1; i <= 7; i++) answers[`dm_item${i}`] = 0;
      for (let i = 1; i <= 5; i++) answers[`dsc_item${i}`] = 0;
      for (let i = 1; i <= 5; i++) answers[`dvp_item${i}`] = 0;
      for (let i = 1; i <= 10; i++) answers[`dvd_item${i}`] = 0;
      return answers;
    },
    submitTest() {
      // Lógica para enviar las respuestas
      console.log(this.answer);
    },
    send_test: function () {
      let test;
      this.answer["semester"] = this.semester;
      testService
        .send_test(this.answer)
        .then((data) => {
          test = data;

          redpieService.assignDocuments({
            test: this.answer.test,
            school: this.$store.state.login.school,
            year: this.$store.state.login.year,
            student_grade: this.answer.student_id,
          });
        })
        .then(
          () => {
            this.$emit("update-document");
            this.$emit("add-test", test);
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al crear test");
          }
        );
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff3e0; /* Color de fondo amarillo claro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #e65100; /* Naranjo oscuro */
}

.section {
  margin-bottom: 20px;
}

h3 {
  background-color: #ffb74d; /* Naranjo medio */
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.question {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

label {
  flex: 1;
  font-weight: bold;
  color: #e65100; /* Naranjo oscuro */
}

.input-field {
  width: 50px;
  text-align: center;
  border: 1px solid #e65100; /* Borde naranjo oscuro */
  border-radius: 5px;
  padding: 5px;
  background-color: #fff3e0; /* Fondo amarillo claro */
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff9800; /* Naranjo fuerte */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #fb8c00; /* Naranjo más oscuro */
}
</style>
